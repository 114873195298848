#cc-main {
	color-scheme: light;
	--cc-font-family: 'Marlin Soft', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	--cc-bg: #f9faff;
	--cc-primary-color: #112954;
	--cc-secondary-color: #112954;

	--cc-btn-primary-bg: hsl(var(--primary-blue));
	--cc-btn-primary-color: #fff;
	--cc-btn-primary-hover-bg: hsl(var(--primary-blue));
	--cc-btn-primary-hover-color: #fff;

	--cc-btn-secondary-bg: transparent;
	--cc-btn-secondary-color: hsl(var(--gray-800));
	--cc-btn-secondary-hover-bg: transparent;
	--cc-btn-secondary-hover-color: hsl(var(--gray-800));

	--cc-cookie-category-block-bg: #ebeff9;
	--cc-cookie-category-block-border: #ebeff9;
	--cc-cookie-category-block-hover-bg: #dbe5f9;
	--cc-cookie-category-block-hover-border: #dbe5f9;
	--cc-cookie-category-expanded-block-hover-bg: #ebeff9;
	--cc-cookie-category-expanded-block-bg: #ebeff9;

	--cc-overlay-bg: rgba(219, 232, 255, 0.85) !important;
	--cc-toggle-readonly-bg: #cbd8f1;
	--cc-toggle-on-knob-bg: #fff;
	--cc-toggle-on-knob-bg: hsl(var(--gray-800));
	--cc-toggle-on-bg: hsl(var(--primary-blue));
	--cc-toggle-off-bg: #fff;
	--cc-toggle-readonly-knob-bg: var(--cc-bg);

	--cc-separator-border-color: #f1f3f5;

	--cc-footer-border-color: #f1f3f5;
	--cc-footer-bg: var(--cc-bg);
}
